import React from 'react';
import UserMenu from './user';
import './index.less';

const Header = () => {
  return (
    <div className="header-wrap">
      <UserMenu />
    </div>
  );
};

export default Header;
