import React, { lazy } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { checkAuth } from '@/utils/helper';
import { treeToArr, arrToTree } from '@zenlayer/zen-design/esm/_util/utils';

export const getMenus = () => {
  const menus: {
    name: string;
    permissions: boolean;
    children: {
      name: string;
      path: string;
      permissions: boolean;
    }[];
  }[] = [
    {
      name: 'Website',
      permissions: checkAuth('menu:partner:getConfiguration'),
      children: [
        {
          name: 'WhiteLabel management',
          path: '/whiteLabel',
          permissions: checkAuth('menu:partner:getConfiguration'),
        },
      ],
    },
    {
      name: 'Customers',
      permissions: checkAuth(
        'partner:endUserPage',
        'waveloa:list',
        'q:partner:pageInfo'
      ),
      children: [
        {
          name: 'Customer Management',
          path: '/customer',
          permissions: checkAuth('partner:endUserPage'),
        },
        {
          name: 'LOA Management',
          path: '/loa',
          permissions: checkAuth('waveloa:list'),
        },
        {
          name: 'Discount Management',
          path: '/discountPage',
          permissions: checkAuth('q:partner:pageInfo'),
        },
        {
          name: 'BGP ASN Application',
          path: '/bgpAsn',
          permissions: checkAuth('p:bgp:pageInfo'),
        },
      ],
    },

    {
      name: 'Billings',
      permissions: checkAuth(
        'partner:billMonthByTeamUid',
        'partner:billByTeam',
        'partner:invoicePage'
      ),
      children: [
        {
          name: 'Billing Information',
          path: '/billing',
          permissions: checkAuth(
            'partner:billMonthByTeamUid',
            'partner:billByTeam'
          ),
        },
        {
          name: 'Invoice management',
          path: '/invoice',
          permissions: checkAuth('partner:invoicePage'),
        },
      ],
    },
    {
      name: 'Platform tool',
      permissions: checkAuth('partner:operateLog', 'q:user:memberPageInfo'),
      children: [
        {
          name: 'Audit Logs',
          path: '/log',
          permissions: checkAuth('partner:operateLog'),
        },
        {
          name: 'Internal Member Permissions',
          path: '/member',
          permissions: checkAuth('q:user:memberPageInfo'),
        },
        {
          name: 'Message Email Management',
          path: '/operationNotice',
          permissions: checkAuth('partner:getOperateMailbox'),
        },
      ],
    },
  ];
  return arrToTree(
    treeToArr(menus, 'name').filter((i) => i.permissions),
    'name'
  );
};

export const router = [
  {
    path: '/dashboard',
    component: lazy(() => import('@/page/dashboard/index')),
    permissions: true,
  },
  {
    path: '/whiteLabel',
    component: lazy(() => import('@/page/whiteLabel')),
    permissions: checkAuth('menu:partner:getConfiguration'),
  },

  {
    path: '/customer',
    component: lazy(() => import('@/page/customer/list')),
    permissions: checkAuth('partner:endUserPage'),
  },
  {
    path: '/customerCreate',
    component: lazy(() => import('@/page/customer/create')),
    permissions: checkAuth('partnerEndUser:add'),
  },
  {
    path: '/billing',
    component: lazy(() => import('@/page/billing/list')),
    permissions: checkAuth('partner:billMonthByTeamUid', 'partner:billByTeam'),
  },
  {
    path: '/invoice',
    component: lazy(() => import('@/page/invoice/list')),
    permissions: checkAuth('partner:invoicePage'),
  },
  {
    path: '/billingDetail',
    component: lazy(() => import('@/page/billing/detail')),
    permissions: checkAuth('partner:billByTeam'),
  },
  {
    path: '/loa',
    component: lazy(() => import('@/page/loa/list')),
    permissions: checkAuth('waveloa:list'),
  },
  {
    path: '/discountPage',
    component: lazy(() => import('@/page/discount/list')),
    permissions: checkAuth('q:partner:pageInfo'),
  },
  {
    path: '/bgpAsn',
    component: lazy(() => import('@/page/bgpAsn/list')),
    permissions: checkAuth('p:bgp:pageInfo'),
  },
  {
    path: '/discountCreate',
    component: lazy(() => import('@/page/discount/create/index')),
    permissions: checkAuth('q:partner:save'),
  },
  {
    path: '/log',
    component: lazy(() => import('@/page/log/list')),
    permissions: checkAuth('partner:operateLog'),
  },
  {
    path: '/member/create',
    component: lazy(() => import('@/page/member/create')),
    permissions: checkAuth('q:user:addMember'),
  },
  {
    path: '/member',
    component: lazy(() => import('@/page/member/list')),
    permissions: checkAuth('q:user:memberPageInfo'),
  },
  {
    path: '/operationNotice',
    component: lazy(() => import('@/page/operationNotice/list')),
    permissions: checkAuth('partner:getOperateMailbox'),
  },
];
