import request from '@/utils/request';
import type { SettingInfo } from '@/utils/helper';

export type UserResp = {
  lastLoginTime: string;
  lastTeamUid: string;
  firstName: string;
  lastName: string;
  uid: string;
  userType: number;
  username: string;
  teamLimit: number;
  source: string;
  interesting: string[];
  token: string;
};

export function getUserInfo() {
  return request<UserResp>({
    method: 'GET',
    url: '/usercenter/user',
  });
}

export interface TeamInfo {
  firstName: string;
  lastName: string;
  teamName: string;
  teamUid: string;
  uid: string;
  userStatus: string;
  username: string;
  domainUrl: string;
}

export function getTeams() {
  return request<TeamInfo[]>({
    method: 'GET',
    url: '/usercenter/partner/user/teams',
  });
}

export function getPermissions() {
  return request<{ allResource: string[] }>({
    method: 'GET',
    url: '/usercenter/partner/user/team/permissions',
  });
}

export function getSettingInfo() {
  return request<SettingInfo>({
    method: 'GET',
    url: '/partner/partner/getSettingInfo',
  });
}
