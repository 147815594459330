/**
 * 通用请求函数
 */
import axios, { AxiosRequestConfig, Canceler, AxiosResponse } from 'axios';
import { notification } from '@zenlayer/zen-design';
import { removeUser, getUser } from '@/utils/helper';
import { encrypt_aes } from '@/utils/aes';

export const _axios = axios.create({
  withCredentials: true, // 携带cookie
  timeout: 65000, // 请求超时时间（ms）
  validateStatus: (status) => !!status, // 定义求情异常状态
});

const skipInterceptors = (response: AxiosResponse) => {
  const { config } = response;
  const skipUrl: string[] = [
    '/api/usercenter/partner/login',
    '/api/usercenter/login',
  ];
  if (skipUrl.some((url) => url === config.url)) {
    return true;
  }

  return false;
};

const downloadBlob = (response: AxiosResponse) => {
  const { config, data } = response;
  if (data) {
    // new Blob([data])用来创建URL的file对象或者blob对象
    let url = window.URL.createObjectURL(new Blob([data]));
    // 生成一个a标签
    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    // 生成时间戳
    let timestamp = new Date().getTime();
    link.download = (config as any).downloadName || `${timestamp}.pdf`;
    document.body.appendChild(link);
    link.click();
  }
};

export const prefix = '/api';
const handleRequestUrl = (url?: string) => `${prefix}${url}`;

let cancelers: { url?: string; method?: string; cancelFn: Canceler }[] = [];
const removeSameRequestPending = (
  ever: AxiosRequestConfig | 'ALL',
  operationCancelFlag?: boolean
) => {
  cancelers = cancelers.filter((canceler) => {
    if (
      ever === 'ALL' ||
      (ever.url === canceler.url && ever.method === canceler.method)
    ) {
      !!operationCancelFlag && canceler.cancelFn();
      return false;
    }
    return true;
  });
};

//请求拦截器
_axios.interceptors.request.use(
  (config) => {
    config.url = handleRequestUrl(config.url);
    if (!config.cancelToken) {
      config.cancelToken = new axios.CancelToken((cancelFn) => {
        cancelers.push({ url: config.url, method: config.method, cancelFn });
      });
    }

    if (getUser()?.token && config.headers) {
      config.headers['X-Zenconsole-Token'] = encrypt_aes(getUser().token);
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//响应拦截器
_axios.interceptors.response.use(
  function (response) {
    const { data, config, status } = response;
    removeSameRequestPending(config);

    if (skipInterceptors(response)) {
      return response;
    }

    if (status === 401) {
      removeUser();
      // notification.error({ description: data?.msg });
      removeSameRequestPending('ALL', true);
      return response;
    }

    if (config.responseType === 'blob') {
      // //文件流处理
      // downloadBlob(response);
      return response;
    }

    // success：false 处理
    if (!data?.success) {
      notification.error({ description: data?.msg });
    }

    return response;
  },

  function (error) {
    return Promise.reject(error);
  }
);

export function fetch<T>(params: AxiosRequestConfig) {
  return _axios.request<T>(params);
}

export function request<T = any>(params: AxiosRequestConfig) {
  return fetch<RequestRes<T>>(params).then((res) => res.data);
}

export const requestFactory = function (prefix: string) {
  return function <T>(params: AxiosRequestConfig) {
    return request<T>({ ...params, url: prefix + params.url });
  };
};

export default request;
