import request from '@/utils/request';

/**
 * 用户登录
 */
export enum UserBelong {
  portal = 'PORAL',
  console = 'CONSOLE',
}
export function loginType(username: string, notThroughPortal?: boolean) {
  return request<UserBelong>({
    method: 'get',
    url: '/usercenter/partner/login',
    params: {
      username,
      notThroughPortal,
    },
  });
}
type LoginReq = {
  password: string;
  username: string;
  tokenTime?: number;
  type: string;
  sig: { data: string };
  from: string | 'Console';
  ts: number;
  source?: string;
  identifier?: string;
  product?: string;

  zgaReportId?: string;

  notThroughPortal?: boolean;
};
export type LoginResp = {
  lastLoginTime?: string;
  lastTeamUid?: string;
  redirectUrl?: string;
  sigCode?: string;
  token?: string;
  userUid?: string;
  forcePassword?: 'open';
};
export function login(data: LoginReq) {
  return request<LoginResp>({
    method: 'post',
    url: '/usercenter/partner/login',
    data,
  });
}

export function verifyCaptcha(data: { code: string; username: string }) {
  return request({
    method: 'post',
    url: '/usercenter/loginCode',
    data,
  });
}

export function resendCaptcha(username: string) {
  return request({
    method: 'put',
    url: `/usercenter/loginCode/resend/${username}`,
  });
}

/**
 * 用户登出
 */
export function logout() {
  return request<null>({
    method: 'post',
    url: '/usercenter/logout',
  });
}
