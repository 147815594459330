import React, { useState, useRef, useMemo } from 'react';
import t from '@zenlayer/zen-intl18';
import {
  Space,
  Icon,
  notification,
  EllipsisTooltip,
  Layout,
} from '@zenlayer/zen-design';
import './index.less';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useHover, useSize } from 'ahooks';
import { stringify } from 'query-string';
import Rocket from '@/assets/images/auth-bg-rocket.svg';
import { sort } from 'ramda';
import { useAddTeamsEventListener, getUser } from '@/utils/helper';
import { TeamInfo } from '@/api';

// const TeamType = ({
//   team,
//   style,
// }: {
//   team: TeamInfo;
//   style?: React.CSSProperties;
// }) => {
//   if (team.type === 'CONTRACT') {
//     return (
//       <div className={cls.vipTag} style={style}>
//         VIP
//       </div>
//     );
//   } else if (team.partner) {
//     return (
//       <div className={cls.partnerTag} style={style}>
//         Partner
//       </div>
//     );
//   }
//   return null;
// };

export default () => {
  const ref = useRef(null);
  const size = useSize(ref);

  const [visible, setVisible] = useState(false);
  useHover(() => document.getElementById('sidebar-team'), {
    onEnter: () => {
      setVisible(true);
    },
    onLeave: () => {
      setVisible(false);
    },
  });

  const [teams, currentTeam] = useAddTeamsEventListener();

  const collapsed = useMemo(() => {
    return !!size && size?.width < 240;
  }, [size]);

  return (
    <div className="siderbar-team-wrap" id="sidebar-team" ref={ref}>
      <div
        className={classnames('team', {
          ['team-collapsed']: !!collapsed,
        })}
      >
        <img src={Rocket} className={'rocket'} />
        <div className={'select'}>
          <span className={'name'}>{currentTeam?.teamName}</span>
          {/* <TeamType team={currentTeam} style={{ marginRight: 8 }} /> */}

          {/* <Icon
            type="DownOutlined"
            fontSize={16}
            rotate={visible ? 180 : undefined}
            style={{ transition: 'ease-in-out 0.3s' }}
          /> */}
        </div>
      </div>
    </div>
  );
};
