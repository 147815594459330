import React, { lazy, Suspense } from 'react';
import { Layout, Button, Sidebar, Loading, Icon } from '@zenlayer/zen-design';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { getMenus, router } from './router';
import Header from '@/component/header';
import Team from '@/component/team';
import '@/assets/style/global.less';
import { useRequest } from 'ahooks';
import { getUserInfo, getTeams, getPermissions, getSettingInfo } from '@/api';
import {
  setUser,
  getUser,
  removeUser,
  useAddUserEventListener,
  setTeams,
  removeTeams,
  setPermissions,
  removePermissions,
  setSettingInfo,
  removeSettingInfo,
} from '@/utils/helper';
import { stringify, parse } from 'query-string';
import { unnest } from 'ramda';

const Main = () => {
  const location = useLocation();

  const UserRequest = useRequest(
    async () => {
      const res = await getUserInfo();
      const { data, success } = res;
      if (success) {
        setUser(data);
        await Promise.resolve({
          then: function (resolve: any, reject: any) {
            //设置User是异步的，所以确保设置成功后结束loading
            const Timer = setInterval(() => {
              if (getUser()) {
                clearInterval(Timer);
                resolve?.(true);
              }
            }, 100);
          },
        });
      } else {
        removeUser();
      }
      return res;
    },
    {
      onSuccess: (res) => {
        //原本是可以并行的，值因为csrf的token是在user里返回的
        if (res.success) {
          TeamsRequest.run();
          PermissionsRequest.run();
          SettingInfoRequest.run();
        }
      },
    }
  );

  const TeamsRequest = useRequest(getTeams, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        setTeams(res.data);
      } else {
        removeTeams();
        removeUser(); // 让他重新回去登陆
      }
    },
  });

  const PermissionsRequest = useRequest(getPermissions, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        setPermissions(unnest(Object.values(res.data)));
      } else {
        removePermissions();
        removeUser(); // 让他重新回去登陆
      }
    },
  });

  const SettingInfoRequest = useRequest(getSettingInfo, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        setSettingInfo(res.data);
      } else {
        removeSettingInfo();
        removeUser(); // 让他重新回去登陆
      }
    },
  });

  const user = useAddUserEventListener();

  if (
    UserRequest.loading ||
    TeamsRequest.loading ||
    PermissionsRequest.loading ||
    SettingInfoRequest.loading
  ) {
    return <Loading.Portal />;
  }

  if (user == undefined) {
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          search: stringify({
            redirectTo: location.pathname + location.search,
          }),
        }}
      />
    );
  }

  return (
    <Layout
      header={<Header />}
      sidebar={
        <Sidebar
          hasTextLogo={<Icon type="logo/LogoHasText_partnerX_dark" />}
          logo={<Icon type="logo/LogoHasText_partner_dark" />}
          responsive="l"
          mainMenus={getMenus()}
          team={<Team />}
        />
      }
    >
      <Switch>
        {router.map((i) => (
          <Route key={i.path} path={i.path} component={i.component} />
        ))}

        <Redirect from="/" to={`/dashboard`} />
      </Switch>
    </Layout>
  );
};

export default () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading.Portal />}>
        <Switch>
          <Route
            path="/auth"
            component={lazy(() => import('@/page/auth/index'))}
          />
          <Route path="/" component={Main} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
