import { useState, useEffect } from 'react';
import Storages from 'js-storage';
import { UserResp } from '@/api';
import { useLatest } from 'ahooks';

const userKey = 'USER';
const userStorage = Storages.localStorage;

function customUserEvent(key: string, value?: UserResp) {
  var event: any = new Event('userStorageEvent');
  event.key = key;
  event.newValue = value;
  window.dispatchEvent(event);
}

export const getUser = (): UserResp => {
  return userStorage.get(userKey);
};

export const setUser = (user: UserResp) => {
  customUserEvent(userKey, user);
  userStorage.set(userKey, user);
};

export const removeUser = () => {
  customUserEvent(userKey, undefined);
  userStorage.remove(userKey);
};

export const addUserEventListener = (
  eventFn: (user: UserResp, e?: Event) => void
) => {
  //当前tab页有效
  window.addEventListener('userStorageEvent', function (e) {
    window.setTimeout(() => {
      eventFn(getUser(), e);
    });
  });
  //非当前tab页有效
  window.addEventListener('storage', function (e) {
    window.setTimeout(() => {
      // console.log(e);
      e.key === userKey && eventFn(getUser(), e);
    });
  });
};

export const useAddUserEventListener = (): UserResp => {
  const [user, setUser] = useState(getUser());
  const latestUserRef = useLatest(user);
  useEffect(() => {
    addUserEventListener((newUser, e) => {
      if (newUser !== latestUserRef.current) {
        setUser(newUser);
      }
    });
  }, []);
  return user;
};
