import CryptoJS from 'crypto-js';

const keyHex = CryptoJS.enc.Utf8.parse('q0w9e8r7t6y5a4sd'); // 密匙
const ivHex = CryptoJS.enc.Utf8.parse('h7d2h2jfg3j1d1g3'); // 偏移量

export const encrypt_aes = (text: string) => {
  const textHex = CryptoJS.enc.Utf8.parse(text);
  return CryptoJS.AES.encrypt(textHex, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

export const decrypt_aes = (encryptedText: string) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedText, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
