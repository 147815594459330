let permissions: string[] = [];

export const getPermissions = (): string[] => {
  return permissions;
};

export const setPermissions = (_permissions: string[]) => {
  permissions = _permissions;
};

export const removePermissions = () => {
  permissions = [];
};

type Arg = string | string[];
// checkAuth("a")  => 有a的权限就返回true
// checkAuth("a","b") => 有a或者b的权限就返回true
// checkAuth(["a","b"]) => 有a并且b的权限才返回true
export const checkAuth = (...args: (Arg | undefined | null)[]) => {
  const _args = args.filter((k) => !!k) as Arg[];
  if (!_args.length) {
    return true;
  }
  return _args.some((k) => {
    const keys = Array.isArray(k) ? k : [k];
    return keys.every((key) => getPermissions().includes(key));
  });
};
