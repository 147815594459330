import { useState, useEffect } from 'react';
import Storages from 'js-storage';
import { TeamInfo } from '@/api';
import { useLatest } from 'ahooks';

const teamsKey = 'TEAMS';
const teamsStorage = Storages.localStorage;

function customTeamsEvent(key: string, value?: TeamInfo[]) {
  var event: any = new Event('teamsStorageEvent');
  event.key = key;
  event.newValue = value;
  window.dispatchEvent(event);
}

export const getTeams = (): TeamInfo[] => {
  return teamsStorage.get(teamsKey);
};

export const getCurrentTeam = (): TeamInfo => {
  return teamsStorage.get(teamsKey)?.[0];
};

export const setTeams = (teams: TeamInfo[]) => {
  customTeamsEvent(teamsKey, teams);
  teamsStorage.set(teamsKey, teams);
};

export const removeTeams = () => {
  customTeamsEvent(teamsKey, undefined);
  teamsStorage.remove(teamsKey);
};

export const addTeamsEventListener = (
  eventFn: (teams: TeamInfo[], currentTeam: TeamInfo, e?: Event) => void
) => {
  //当前tab页有效
  window.addEventListener('teamsStorageEvent', function (e) {
    window.setTimeout(() => {
      eventFn(getTeams(), getCurrentTeam(), e);
    });
  });
  //非当前tab页有效
  window.addEventListener('storage', function (e) {
    window.setTimeout(() => {
      e.key === teamsKey && eventFn(getTeams(), getCurrentTeam(), e);
    });
  });
};

export const useAddTeamsEventListener = (): [TeamInfo[], TeamInfo] => {
  const [teams, setTeams] = useState(getTeams());
  const [currentTeam, setCurrentTeam] = useState(getCurrentTeam());
  const latestTeamsRef = useLatest(teams);
  const latestCurrentTeamRef = useLatest(currentTeam);
  useEffect(() => {
    addTeamsEventListener((newTeams, newCurrentTeam, e) => {
      if (newTeams !== latestTeamsRef.current) {
        setTeams(newTeams);
      }
      if (newCurrentTeam !== latestCurrentTeamRef.current) {
        setCurrentTeam(newCurrentTeam);
      }
    });
  }, []);
  return [teams, currentTeam];
};
