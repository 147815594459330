import {
  initThemes,
  Theme,
  getTheme,
  setTheme,
} from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';
import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/style/global.less';
import App from './page/index';

(function bs() {
  const themes: Theme[] = ['dark'];
  initThemes({ themes });

  const cyrentTheme = getTheme();
  if (!themes.includes(cyrentTheme)) {
    setTheme(themes[0]);
  }
})();

const container = document.getElementById('root') as HTMLElement;

ReactDOM.createRoot(container).render(React.createElement(App));
