export interface SettingInfo {
  subDiscountsManage: 0 | 1; // 0代表不开启 1代表开启
}

let settingInfo: SettingInfo | undefined;

export const getSettingInfo = (): SettingInfo | undefined => {
  return settingInfo;
};

export const setSettingInfo = (_settingInfo: SettingInfo) => {
  settingInfo = _settingInfo;
};

export const removeSettingInfo = () => {
  settingInfo = undefined;
};
